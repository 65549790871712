import anika from "../../../../images/Logo101.png"
import biopharm from "../../../../images/Logo102.png"
import sanofi from "../../../../images/Logo103.png"
import amarox from "../../../../images/Logo104.png"
import daryaVaria from "../../../../images/Logo11.png"
import merck from "../../../../images/Logo14.png"
import sydna from "../../../../images/Logo2.png"
import kimiaFarma from "../../../../images/Logo23.png"
import kalbe from "../../../../images/Logo3.png"
import combiphar from "../../../../images/Logo32.png"
import delasalle from "../../../../images/Logo48.png"
import brin from "../../../../images/LogoBrin.png"
import danone from "../../../../images/LogoDanone.png"
import novotech from "../../../../images/novotech.jpeg"

const ClientImages = () => {
  return (
    <section className="clients">
      <div className="container">
        <h6 className="title-heading">Our Clients</h6>
        <div className="client-images-wrap">
          <img src={daryaVaria} alt="daryaVaria" width={159} height={113} />
          <img src={sydna} alt="sydna" width={141} height={39} />
          <img src={kalbe} alt="kalbe" width={142} height={63} />
          <img src={kimiaFarma} alt="kimiaFarma" width={186} height={67} />
          <img src={merck} alt="merck" width={159} height={90} />
          <img src={delasalle} alt="delasalle" width={71} height={71} />
          <img src={anika} alt="anika" width={152} height={76} />
          <img src={biopharm} alt="biopharm" width={163} height={80} />
          <img src={sanofi} alt="sanofi" width={229} height={84} />
          <img src={amarox} alt="amarox" width={176} height={46} />
          <img src={brin} alt="brin" width={210} height={81} />
          <img src={danone} alt="danone" width={73} height={92} />
          <img src={combiphar} alt="combiphar" width={225} height={61} />
          <img src={novotech} alt="novotech" width={225} height={80} />
          <span className="client-image-span">And many more</span>
        </div>
      </div>
    </section>
  )
}

export default ClientImages
